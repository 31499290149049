.container {
  $scale: 1/2;

  display: inline-block;
  position: relative;

  &,
  .device,
  .screen,
  .head,
  .foot {
    transition: all 0.5s ease;
    transform-origin: center center;
  }
  .device,
  .screen,
  .head,
  .foot {
    position: absolute;
    top: 0;
    left: 0;
  }
  .device {
    background: #222;
    box-shadow: 0 0 1px white;

    .screen {
      background: #303030;
    }
  }
  .head {
    background: #b8b8b8;
  }
  .foot {
    background: #ccc;
  }

  &.tv {
    width: 600px * $scale;
    height: 325px * $scale;

    .device {
      width: 600px * $scale;
      height: 300px * $scale;
      border-radius: 3px;

      .screen {
        top: 4%;
        left: 10%;
        right: 10%;
        bottom: 4%;
      }
    }
    .head {
      width: 200px * $scale;
      height: 20px * $scale;
      left: 200px * $scale;
      top: 300px * $scale;
      background: #000;
    }
    .foot {
      width: 300px * $scale;
      height: 5px * $scale;
      left: 150px * $scale;
      top: 320px * $scale;
      background: #222;
    }
  }

  &.desktop {
    width: 400px * $scale;
    height: 325px * $scale;

    .device {
      width: 400px * $scale;
      height: 270px * $scale;
      border-radius: 15px;

      .screen {
        top: 8%;
        left: 6%;
        right: 6%;
        bottom: 8%;
      }
    }
    .head {
      width: 100px * $scale;
      height: 50px * $scale;
      left: 150px * $scale;
      top: 270px * $scale;
    }
    .foot {
      width: 120px * $scale;
      height: 5px * $scale;
      left: 140px * $scale;
      top: 320px * $scale;
    }
  }

  &.laptop {
    width: 290px * $scale;
    height: 185px * $scale;

    .device {
      width: 250px * $scale;
      height: 170px * $scale;
      left: 20px * $scale;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;

      .screen {
        top: 8%;
        left: 4%;
        right: 4%;
        bottom: 6%;
      }
    }
    .head {
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
    }
    .foot {
      width: 290px * $scale;
      height: 15px * $scale;
      top: 170px * $scale;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &.tablet {
    width: 130px * $scale;
    height: 170px * $scale;

    .device {
      width: 100%;
      height: 100%;
      border-radius: 7px;

      .screen {
        top: 10%;
        left: 10%;
        right: 10%;
        bottom: 12%;
      }
    }
    .head {
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
    }
    .foot {
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
    }

    &.landscape {
      width: 170px * $scale;
      height: 130px * $scale;
    }
  }

  &.phone {
    width: 60px * $scale;
    height: 100px * $scale;

    .device {
      width: 100%;
      height: 100%;
      border-radius: 7px;

      .screen {
        top: 10%;
        left: 10%;
        right: 10%;
        bottom: 12%;
      }
    }
    .head {
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
    }
    .foot {
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
    }

    &.landscape {
      width: 100px * $scale;
      height: 60px * $scale;
    }
  }
}
